import React, { Component } from "react";
import { Icon } from "@material-ui/core";
import styled from "styled-components";
import { PresentationLibraryViewSelect } from "legacy-js/editor/PresentationLibrary/PresentationLibraryViewSelect";
import { UIPaneSideBar, UIPaneWithSideBar } from "legacy-js/react/components/UiComponents";
import { SideBarItem } from "legacy-js/editor/PresentationLibrary/PresentationLibrarySideBar";
import { Gap10 } from "legacy-js/react/components/Gap";
import AppController from "legacy-js/core/AppController";
import { LibraryView } from "legacy-common/constants";
import { ManageSavedThemes } from "legacy-js/editor/ThemeEditor/panes/ManageSavedThemes";
import { OnMountedReporter } from "js/core/utilities/useMountedAt";

export const MyResourceTypes = {
    SAVED_THEMES: "saved-themes",
};

const Styler = styled.div`
    width: 100%;

    .search-container {
        padding-top: 5px;
    }
`;

export default class MyResources extends Component {
    state = {
        selectedPane: MyResourceTypes.SAVED_THEMES
    }

    constructor(props) {
        super(props);

        if (props.pane) {
            this.state.selectedPane = props.pane;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.pane != prevProps.pane) {
            this.setState({ selectedPane: this.props.pane });
        }
    }

    handleSelectResourceType = type => {
        AppController.showMyResources({ pane: type });
    }

    renderSelectedPane = () => {
        let { selectedPane } = this.state;

        switch (selectedPane) {
            case MyResourceTypes.SAVED_THEMES:
                return <ManageSavedThemes />;
        }
    }

    // NOTE: We have to use `className="selected"` instead of `props.selected`
    //   because a bug with `styled and` the application of `.dark-mode`
    selectedClass(filter) {
        return filter ? "selected" : "";
    }

    render() {
        let { selectedPane } = this.state;
        let { onSwitchType, sideBarWidth, onResizeSidebar, onReady = () => { } } = this.props;

        return (
            <UIPaneWithSideBar>
                <UIPaneSideBar
                    style={{ width: sideBarWidth }}
                    onResize={onResizeSidebar}
                >
                    <PresentationLibraryViewSelect libraryView={LibraryView.MY_RESOURCES} onSwitchType={onSwitchType} />
                    <SideBarItem
                        className={this.selectedClass(selectedPane == MyResourceTypes.SAVED_THEMES)}
                        onClick={() => this.handleSelectResourceType(MyResourceTypes.SAVED_THEMES)}
                    >
                        <Icon>palette</Icon>
                        Saved Themes
                    </SideBarItem>
                </UIPaneSideBar>

                <Gap10 />

                <Styler>
                    <OnMountedReporter onMounted={onReady} />
                    {this.renderSelectedPane()}
                </Styler>
            </UIPaneWithSideBar>
        );
    }
}
